export default class DetailViewManager{

	constructor(detailViewManagerDom){
		this._dom = detailViewManagerDom;
	  this._dom.data("object",this);
	  this._selectedIndex = 0;
	  this._initButtons();
	  this.selectByIndex(0);
	}

	//public
	selectByIndex(index){
		this._select(index);
	}

	selectedIndex(){
		return this._selectedIndex;
	}

	//private
	_hideDetailViews(){
		this._dom.find(".detail-view").hide();
	}

	_select(index){
		this._selectedIndex = index;
		var btn = this._dom.find(".detail-view-btn:eq(" + index + ")");
		var view = this._dom.find(".detail-view:eq(" + index + ")");
		this._clear();
		btn.addClass('selected');
		view.show();

		$("li.leaders").each(function(){
		    var p_tag = $(this).find("p").first();
		    if(p_tag.length > 0){
		        $(this).css("height", p_tag.height());
		    }
		});

	}

	_clear(){
		this._dom.find(".detail-view-btn").removeClass('selected');
		this._hideDetailViews();
	}

	_initButtons(){
	  var thisDetailViewManager = this;
	  this._dom.find(".detail-view-btn").click(function(){
	  	var $this = $(this);
	  	var selected = $this.hasClass('selected');
	  	if(!selected){
	  		thisDetailViewManager.selectByIndex($this.index());
	  	}
	  });
	}
}