export default class NCAPTSModal{

	constructor(modalDom){

		// private
		this._dom = modalDom;
		this._dom.data("object", this);
		this._stack = [];
		this._inputValues = [];

		// private - these must be set in the set_params function 
		this._title = "";
		this._subTitle = "";
		this._submitType = "";
		this._deleteCallback = "";
		this._submitCallback = "";
		this._model = "";
		this._deletable = false;
		this._additionalParameters = {};


		// public 
		this.populateUrl = "";
		this.submitUrl = "";
		this.deleteUrl = "";
		this.retrieveStackDataOnSubmitFunction = "";
		this.stackData = {"base": [], "class" : []};
	}

	//public

	set_params(params){
		this._title = params["title"] ? params["title"] : "";
		this._subTitle = params["sub-title"] ? params["sub-title"] : "";
		this._submitType = params["submit-type"] ? params["submit-type"] : "";
		this._deleteCallback = params["delete-callback"] ? params["delete-callback"] : "";
		this._submitCallback = params["callback"] ? params["callback"] : "";
		this._model = params["model"] ? params["model"] : "";
		this._deletable = params["deletable"] && params["deletable"] == true ? true : false;
		this._additionalParameters = params["additional-parameters"] ? params["additional-parameters"] : "";
		this.populateUrl = params["populate-url"];
		this.submitUrl = params["submit-url"];
		this.deleteUrl = params["delete-url"];
	}

	show(){
		this._emptyStack();
		this._populateDomAjax();
		this._startInterval();
	}

	close(){
		M.Modal.getInstance(this._dom).close();
	}

	isOpen(){
		return M.Modal.getInstance(this._dom).isOpen;
	}

	init(){
		this._initDom();
	}

	popModalStack(){
		this._popStack();
		this._populateDomAjax();
	}

	//private
	_startInterval(){
		if(this._dom.hasClass('iframe-modal')){
			this._stopInterval();
			var thisModal = this;
			this._interval = setInterval(function(){thisModal._lookForClosePrompt()}, 4000);
		}
	}

	_stopInterval(){
		if(this._interval){
			clearInterval(this._interval);
		}
	}

	_lookForClosePrompt(){
		if(this._dom.find('iframe').contents().find("#close-modal").length > 0){
		 	this.close();
		 	this._stopInterval();
			var msg = this._dom.find('iframe').contents().find("#message").html();
			msg = msg + "<br/>To complete your request, this page will refresh in 5 seconds."
			setTimeout(function(){
			   window.location.reload(1);
			}, 5000);
			_ncaptsPage.successAlert(msg);

		} else if (!this.isOpen()){
			this._stopInterval();
		}
	}

	_populateDomAjax(){
		var additionalParameters = this._additionalParameters
		var data = {};
		var url = this.populateUrl;
		if(Object.keys(additionalParameters).length > 0){
			url = url + "?";
			Object.keys(additionalParameters).forEach(function (key) { 
		    url = url + key + "=" + additionalParameters[key] + "&";
			})
			url = url.slice(0, -1);
		}
		_ncaptsPage.ajaxJsGetCall(url, data);
	}

	_initDom(){
		if(this._title != ""){
			this._dom.find(".title").html(this._title);
		}
		if(this._subTitle != ""){
			this._dom.find(".sub-title").html(this._subTitle);
		}

		var thisModal = this;

		var deleteBtn = this._dom.find(".delete-btn")
		if (this._deletable){
			deleteBtn.removeClass("hidden");
			deleteBtn.click(function(event){
				_ncaptsPage.confirmModal(function(){thisModal._deleteClickAjax();});
			});
		}else{
			deleteBtn.addClass("hidden");
		}
		
		_ncaptsPage.init("#" + this._dom.attr("id"));
		M.Modal.getInstance(this._dom).open();

		this._dom.find(".back-btn").click(function(){
			thisModal._popStack();
			thisModal._populateDomAjax();
		});

		this._dom.find(".submit-btn").click(function(){
			thisModal._submitClick();
		});

		this._dom.find(".push-modal").click(function(){
			thisModal._newButtonClicked($(this));
		})

		if(this._stack.length > 0){
			this._dom.find(".close-btn").hide();
		}else{
			this._dom.find(".back-btn").hide();
		}

		if(this._inputValues.length > 0){
			for(var i = 0; i < this._inputValues.length; i++){
				this._dom.find("[name=" + this._inputValues[i]["key"] + "]").val(this._inputValues[i]["value"]).trigger("change");
			}
		}

		if(this._dom.find(".submit-on-enter".length > 0)){
			this._dom.find(".submit-on-enter").first().keypress(function (e) {
			  if (e.which == 13) {
			    thisModal._submitClick();
			    return false;
			  }
			});
		}

		//Focus First Input
		// this._dom.find("input").first().select().focus();

	}

	_deleteClickAjax(){
		var thisModal = this;
		$.ajax({
		  url: this.deleteUrl,
			type: "DELETE",
			data: {},
			success: function(json){_ncaptsPage.modalCallback(json,thisModal._deleteCallback)},
		  error: function(json){_ncaptsPage.errorAlert($.parseJSON(json.responseText).errors);},
		  dataType: 'json'
		});
	}

	_submitClick(){
		if(this.submitUrl == "[no-post-retain-data]"){
			if(this.retrieveStackDataOnSubmitFunction){
				var dataFromPoppedModal = this.retrieveStackDataOnSubmitFunction();
			}
			this._popStack();
			if(dataFromPoppedModal){
				if(dataFromPoppedModal["base"]){
					for (var key in dataFromPoppedModal["base"]) {
						this.stackData["base"][key] = dataFromPoppedModal["base"][key];
					}
				}
				if(dataFromPoppedModal["class"]){
					for (var key in dataFromPoppedModal["class"]) {
						this.stackData["class"][key] = dataFromPoppedModal["class"][key];
					}
				}
			}
			this._populateDomAjax();
		}else{
			this._submitClickAjax();
		}
	}

	_submitClickAjax(){
		var inputValues = {};
		var baseValues = {};
		var dont_append_ids = this._dom.hasClass('no-input-ids');
		var inputDoms = this._dom.find(".input").each(function(){
			var $this = $(this);
			if(!$this.hasClass("ignore")){
				var key = $this.hasClass('id-input') && !dont_append_ids ? $this.attr("name") + "_id" : $this.attr("name");
				if($this.hasClass('checkbox')){
					if ($this.prop('checked')){
						value = true;
					}else{
						value = false;
					}
				}else{
					var value = $this.val();
				}
				if($this.hasClass("base")){
					baseValues[key] = value
				}else{
					inputValues[key] = value;
				}
			}
		});

		for (var key in this.stackData["base"]) {
			baseValues[key] = this.stackData["base"][key]
		}
		for (var key in this.stackData["class"]) {
			inputValues[key] = this.stackData["class"][key]
		}

		var data = {};
		data["base"] = baseValues
		data[this._model] = inputValues;

		var thisModal = this;
		$.ajax({
		  url:  this.submitUrl,
			type: this._submitType,
			data: data,
			success: function(json){_ncaptsPage.modalCallback(json,thisModal._submitCallback);},
		  error: function(json){_ncaptsPage.errorAlert($.parseJSON(json.responseText).errors);},
		  dataType: 'json'
		});
	}

	_newButtonClicked(btnDom){
		this._addCurrentToStack();
		this._inputValues = [];
		this._title = btnDom.data("title");
		this._subTitle = btnDom.data("sub-title");
		this._submitType = "POST";
		this._deleteCallback = ""
		this._submitCallback = "[pop-modal]";
		this._model = btnDom.data("model");
		this._deletable = false;
		this.populateUrl = btnDom.data("populate-url");
		this.submitUrl = btnDom.data("submit-url");
		this.deleteUrl = null;
		this._additionalParameters = {};

		this._populateDomAjax();
	}

	_addCurrentToStack(){
		var inputs = []
		this._dom.find(".input").each(function(){
			inputs.push({key: $(this).attr('name'), value: $(this).val()});
		});
		var stackObject = {
			inputs: inputs,
			submitType: this._submitType,
			deleteCallback: this._deleteCallback,
			submitCallback: this._submitCallback,
			model: this._model,
			title: this._title,
			subTitle: this._subTitle,
			populateUrl: this.populateUrl,
			submitUrl: this.submitUrl,
			deleteUrl: this.deleteUrl,
			deletable: this._deletable,
			additionalParameters: this._additionalParameters,
			retrieveStackDataOnSubmitFunction: this.retrieveStackDataOnSubmitFunction,
			stackData: this.stackData
		}

		this._stack.push(stackObject);
	}

	_popStack(){
		var stackObject = this._stack.pop();
		this._setStateToStackObject(stackObject);
	}

	_emptyStack(){
		if(this._stack.length > 0){
			this._setStateToStackObject(this._stack[0]);
		}
		this._stack = [];
		this._inputValues = [];
	}

	_setStateToStackObject(obj){
		this._submitType = obj["submitType"];
		this._deleteCallback = obj["deleteCallback"];
		this._submitCallback = obj["submitCallback"];
		this._model = obj["model"];
		this._title = obj["title"];
		this._subTitle = obj["subTitle"];
		this.populateUrl = obj["populateUrl"];
		this.submitUrl = obj["submitUrl"];
		this.deleteUrl = obj["deleteUrl"];
		this.retrieveStackDataOnSubmitFunction = obj["retrieveStackDataOnSubmitFunction"];
		this.stackData = obj["stackData"];
		this._deletable = obj["deletable"];
		this._additionalParameters = obj["additionalParameters"];
		this._inputValues = obj["inputs"];
	}

}
