/* add Custom Classes to the browser */
import NCAPTSModal from 'packs/ncapts-modal';
import AjaxDataTable from 'packs/ajax-datatable';
import AjaxCheckboxCollection from 'packs/ajax-checkbox-collection';
import DetailViewManager from 'packs/detail-view-manager';
import RelationshipViewManager from 'packs/relationship-view-manager';

// Init Ncapts Page
var _ncaptsPage;
$(function() {
	window._ncaptsPage = new NCAPTSPage($("#ncapts-page").first());
	window._ncaptsPage.init();
});

class NCAPTSPage{

	constructor(pageDom){
		this._dom = pageDom;
		this._dom.data("ncapts-object", this);
		this._pageUrl = pageDom.find("#page-url-holder").length > 0 ? pageDom.find("#page-url-holder").first().data("url") : null;
		this._detailViewManager = null
	}

	//public
	init(selector = ""){
		if(selector == ""){
			this._populateDomAjax();
		}
		this._populateItemsAjax(selector);
		this._initAjaxDatatable(selector);
		this._initAjaxCheckboxCollection(selector);
		this._initStaticDatatables(selector);
		this._initModal(selector);
		this._initMaterializeComponents();
		this._initRelationshipViewManager(selector);
		this._initDetailViewManager(selector);
		this._initSelect2(selector);
		this._initCurrencyInputs(selector);
		this._initPageAlerts(selector);
		this._initInlineEditCels(selector);
		this._initBestInPlace(selector);
	}

	getApplicationModal(){
		return $("#application-modal").first().data("object");
	}

	getApplicationIframeModal(){
		return $("#application-iframe-modal").first().data("object");
	}

	getOpenModal(){
		if(this.getApplicationModal().isOpen()){
			return this.getApplicationModal();
		}else if (this.getApplicationIframeModal().isOpen()){
			return this.getApplicationIframeModal();
		}
		return null;
	}

	closeModals(){
		this.getApplicationModal().close();
		this.getApplicationIframeModal().close();
	}

	confirmModal(fn, message = null){
		message = message ? message.replaceAll("---", " ") : null;
		console.log(message);
		M.Modal.getInstance($('#confirm-modal')).open();
		if(message){
			$('#confirm-modal .message').show().html(message);
		}else{
			$('#confirm-modal .message').hide().html("");
		}
		$('#confirm-modal-yes-btn').unbind().click(fn); 
	}

	// create(json){
	// 	this.refresh();
	// }

	modalCallback(json, params=""){
		this.checkJsonForNotification(json);

		if(params.indexOf("[redirect]") != -1){
			this._redirectCallback(json);
		}
		if(params.indexOf("[refresh]") != -1){
			this._refreshCallback(json);
		}
		if(params.indexOf("[reload]") != -1){
			window.location.reload(1);
		}
		if(params.indexOf("[refresh-datatables]") != -1){
			this._refreshDataTablesCallback(json);
		}
		if(params.indexOf("[delete]") != -1){
			this._deleteCallback(json);
		}
		if(params.indexOf("[create]") != -1){
			this._createCallback(json);
		}
		if(params.indexOf("[pop-modal]") != -1){
			this._popModalCallback(json);
		}
	}

	refresh(selector = ""){
		this.closeModals();
		this._refreshAjaxDataTables(selector);
		this._refreshRelationshipViewManager(selector);
		this.init(selector);
	}

	setTagHtml(tag, html){
		$(tag).html(html);
		this.init(tag);
	}

	// populateItem(id, html){
	// 	$("#" + id).html(html);
	// 	this.init("#"+id);
	// }

	objectAjaxCreate(url, data, callback = ""){
		$.ajax({
		  url:  url,
			data: data,
			success: function(json){window._ncaptsPage.modalCallback(json, callback)},
		  error: function(json){window._ncaptsPage.checkJsonForNotification($.parseJSON(json.responseText));},
		  type: "POST",
		  dataType: 'json'
		});
	}

	objectAjaxUpdate(url, data, callback = ""){
		$.ajax({
		  url:  url,
			data: data,
			success: function(json){window._ncaptsPage.modalCallback(json, callback)},
		  error: function(json){window._ncaptsPage.checkJsonForNotification($.parseJSON(json.responseText));},
		  type: "PUT",
		  dataType: 'json'
		});
	}

	// objectAjaxDelete(url, callback = ""){
	// 	$.ajax({
	// 	  url:  url,
	// 		success: function(json){_reefPage.modalCallback(json, callback)},
	// 	  error: function(json){_reefPage.checkJsonForNotification($.parseJSON(json.responseText));},
	// 	  type: "DELETE",
	// 	  dataType: 'json'
	// 	});
	// }

	ajaxJsGetCall(url, data = {}){
		$.ajax({url: url, data: data, type: "GET" });
	}

	// ajaxJsonGetCall(url, callback, data = {}){
	// 	$.ajax({
	// 	  url:  url,
	// 		data: data,
	// 		success: function(json){_reefPage.checkJsonForNotification(json), callback(json);},
	// 	  error: function(json){_reefPage.checkJsonForNotification($.parseJSON(json.responseText));},
	// 	  type: "GET",
	// 	  dataType: 'json'
	// 	});
	// }

	ajaxJsonPutCall(url, callback, data = {}){
		$.ajax({
		  url:  url,
			data: data,
			success: function(json){window._ncaptsPage.checkJsonForNotification(json), callback ? callback(json) : function(json){};},
		  error: function(json){window._ncaptsPage.checkJsonForNotification($.parseJSON(json.responseText));},
		  type: "PUT",
		  dataType: 'json'
		});
	}

	ajaxJsonPostCall(url, callback, data = {}){
		$.ajax({
		  url:  url,
			data: data,
			success: function(json){window._ncaptsPage.checkJsonForNotification(json), callback ? callback(json) : function(json){};},
		  error: function(json){window._ncaptsPage.checkJsonForNotification($.parseJSON(json.responseText));},
		  type: "POST",
		  dataType: 'json'
		});
	}

	ajaxJsonDeleteCall(url, callback, data = {}){
		$.ajax({
		  url:  url,
			data: data,
			success: function(json){window._ncaptsPage.checkJsonForNotification(json), callback ? callback(json) : function(json){};},
		  error: function(json){window._ncaptsPage.checkJsonForNotification($.parseJSON(json.responseText));},
		  type: "DELETE",
		  dataType: 'json'
		});
	}

	checkJsonForNotification(json){
		if(json.errors){
			var msg = json.errors
			if(json.refresh){
				msg = msg + "<br/>This page will refresh in 5 seconds to keep information up-to-date."
				setTimeout(function(){
				   window.location.reload(1);
				}, 5000);
			}
			this.errorAlert(msg);
		}
		if(json.success){
			var msg = json.success
			var url = json.url

			if(json.refresh){
				msg = msg + "<br/>To complete your request, this page will refresh in 5 seconds."
				setTimeout(function(){
				   window.location.reload(1);
				}, 5000);
			}
			this.successAlert(msg, url);
		}
	}

	successAlert(message, url = null){
		var urlText = url ? 
			"<button onclick=\"window.location =  '" + url + "';\" class='btn-flat toast-action'>Go To</button>" : ""
		M.toast({
			html: "<span><i class='fas fa-check-circle'></i> " + message + "</span> " + urlText,
			classes: 'ncapts-green'
		});
	}

	noticeAlert(message, url = null){
		M.toast({
			html: "<span><i class='fas fa-info-circle'></i> " + message + "</span> ",
			classes: 'ncapts-highlight'
		});
	}

	errorAlert(message){
		M.toast({
			html: "<span><i class='fas fa-exclamation-circle'></i> " + message + "</span> <button onclick='M.Toast.dismissAll();' class='btn-flat toast-action'>OK</button>",
			classes: 'ncapts-red',
			displayLength: 10000000
		});
	}

	infoAlert(message){
		M.toast({
			html: "<span><i class='fas fa-info-circle'></i> " + message + "</span> <button onclick='M.Toast.dismissAll();' class='btn-flat toast-action'>OK</button>",
			classes: 'ncapts-highlight',
			displayLength: 10000000
		});
	}

	//private

	_initAjaxDatatable(selector = "") {
		this._dom.find(selector + ' .ajax-dataTable').not(".initialized").each(function(){
			var table = new AjaxDataTable($(this));
			$(this).addClass("initialized");
		});
	}

	_initAjaxCheckboxCollection(selector = "") {
		this._dom.find(selector + ' .ajax-checkbox-collection').not(".initialized").each(function(){
			var collection = new AjaxCheckboxCollection($(this));
			$(this).addClass("initialized");
		});
	}	

	_initStaticDatatables(selector=""){
		this._dom.find(selector + ' .static-dataTable').not(".initialized").each(function(){

			var options = {
				"dom": '<"top"<"title-block">fl>rt<"bottom"ip><"clear">',
			  "pagingType": "full_numbers",
				"order": [[ $(this).data("order-col") ? $(this).data("order-col") : 0,
					$(this).data("order-dir") ? $(this).data("order-dir") : "desc"]],
			  "stateSave": true,
			  language: {
			    search: "_INPUT_",
			    searchPlaceholder: "Search...",
					lengthMenu: "_MENU_"
			  }
			}
			if ($(this).data("disable-sort") == true){
				options["ordering"] = false;
			}
			if ($(this).data("scroll-x") == true){
				options["scrollX"] = true;
			}
			if ($(this).data("scroll-y")){
				options["scrollY"] = $(this).data("scrollY");
			}
			if ($(this).data("fixed-columns") == true){
				options["fixedColumns"] = true;
			}

			$(this).DataTable(options);
			$(this).addClass("initialized");
		});
	}

	_initInlineEditCels(selector=""){
		this._dom.find(selector + ' .inline-edit-cel').not(".initialized").each(function(){
			var $this = $(this);
			var one_click = false;
			$(this).find("i").hide();
			$(this).closest("td").hover( function(){
				if($(this).find("input").length == 0){
					$(this).find("i").show();
				}
			}, function(){
				$(this).find("i").hide();
			}).addClass('inline-edit-cel').click(function(event){
				if(one_click == false){
					one_click = true;
					$(this).find("i").hide();
					$(this).find("span").click();
					one_click = false;
				}
			});
			$this.addClass("initialized");
		});
	}

	_initBestInPlace(selector = "") {
		this._dom.find(selector + ' .best_in_place').not(".initialized").each(function(){
			var bip = $(this)
			bip.best_in_place();
			bip.bind("ajax:success", function (event, data, status, xhr) {
				window._ncaptsPage.checkJsonForNotification($.parseJSON(data));
			});
			bip.bind("ajax:error", function (event, data, status, xhr) {
				window._ncaptsPage.checkJsonForNotification($.parseJSON(data.responseText));
			});
			bip.addClass("initialized");
		});
	}

	_initModal(selector = "") {
		this._dom.find(selector + ' .modal').not(".initialized").each(function(){
			var modal = new NCAPTSModal($(this));
			$(this).addClass("initialized");
		});
	}

	_initMaterializeComponents(){
		$('.sidenav').sidenav();
		$('.dropdown-trigger').dropdown({hover: false, coverTrigger: false, autoTrigger: false, inDuration: 500, alignment: 'right',
				constrainWidth: false, autoFocus: false});
		$('.collapsible').collapsible();
		$('input.counted, textarea.counted').characterCounter();
		$('.modal').modal();
		$('select').not(".select2, .select2-icon").formSelect();
		$('.fixed-action-btn').floatingActionButton({hoverEnabled: false});
		if($('.fixed-action-btn').length > 0 && $('.fixed-action-btn').not(".initialized").length > 0){
			M.FloatingActionButton.getInstance($('.fixed-action-btn')).open();
			$('.fixed-action-btn').addClass("initialized");
		}
		$('.tooltipped').tooltip();
		$('.collapsible').collapsible();

		var elems = document.querySelectorAll('.datepicker');
    var instances = M.Datepicker.init(elems, {container: "body", autoClose: "true"});
  	$('.datepicker').focus(function(){
  		var instance = M.Datepicker.getInstance(this);
  		instance.open();
  	});


    $('.timepicker').each(function(){
    	var options = {
		    interval: 15,
		    dynamic: false,
		    dropdown: true,
		    scrollbar: true,
		    change: function(){M.updateTextFields();}
			}
	   	options["timeFormat"] = $(this).data("format") && $(this).data("format") != "" ? $(this).data("format") : 'h:mm p'
	   	$(this).timepicker(options);
    })

		// var elems = document.querySelectorAll('.timepicker');
  //   var instances = M.Timepicker.init(elems, {container: "body", twelveHour: false });

		// $('.timepicker').on('change', function() {
		// 	var splitTime = this.value.split(":");
		// 	var hours = parseInt(splitTime[0]);
		// 	var minutes = parseInt(splitTime[1]);
		// 	var rounded = ((minutes/15) | 0) * 15
		// 	var newTime = "" + hours + ":" + rounded + "";
		// 	this.value = newTime;
		// })		
	}

	_initRelationshipViewManager(selector = "") {
		this._dom.find(selector + ' .relationship-view-manager').not(".initialized").each(function(){
			var viewManager = new RelationshipViewManager($(this));
			$(this).addClass("initialized");
		});
	}

	_initDetailViewManager(selector = "") {
		var thisObj = this;
		this._dom.find(selector + ' .detail-view-manager').not(".initialized").each(function(){
			var index = thisObj._detailViewManager ? thisObj._detailViewManager.selectedIndex() : 0;
			thisObj._detailViewManager = new DetailViewManager($(this));
			thisObj._detailViewManager.selectByIndex(index);
			$(this).addClass("initialized");
		});
	}

	_initSelect2(selector = ""){
		var thisObj = this;
		this._dom.find(selector + ' .modal-content select.select2').not(".initialized").addClass('initialized').select2({
			 	templateResult: this._resultState,
        width: '100%',
        dropdownParent: $("#application-modal")
    });
		this._dom.find(selector + ' select.select2').not(".initialized").addClass('initialized').select2();
		this._dom.find(selector + ' select.select2-icon').not(".initialized").addClass('initialized').select2({
    templateSelection: thisObj._formatSelect2Icon,
    templateResult: thisObj._formatSelect2Icon,
	  minimumResultsForSearch: Infinity});
	}

	//For Select 2 linking to original DOM object
	_resultState(data, container) {
	    if(data.element) {
	        $(container).addClass($(data.element).attr("class"));
	    }
	    return data.text;
	}

	_formatSelect2Icon (icon) {
		return $('<span><i class="' + $(icon.element).data('icon') + '"></i> ' + icon.text + '</span>');
	};

	_initCurrencyInputs(selector = ""){
		var thisObj = this;
		this._dom.find(selector + ' input.currency').not(".initialized").addClass('initialized').each(function(){
			var currencyInput = $(this)

			 // format inital value
			window.currencyInputOnBlur({target:currencyInput})

			// bind event listeners
			currencyInput.on('focus', window.currencyInputOnFocus)
			currencyInput.on('blur', window.currencyInputOnBlur)
		});
	}	

	_initPageAlerts(selector = ""){
		var thisObj = this;
		this._dom.find(selector + ' .page-alert').not(".initialized").addClass('initialized').each(function(){
			var alert = $(this)
			var msg = alert.html();
			if(alert.data("type") == "success"){
				thisObj.successAlert(msg);
			}else if (alert.data("type") == "error"){
				thisObj.errorAlert(msg);
			}else if (alert.data("type") == "notice"){
				thisObj.noticeAlert(msg);
			}
		});
	}

	_refreshAjaxDataTables(selector = "") {
		this._dom.find(selector + ' .ajax-dataTable').each(function(){
			$(this).data("object").refresh();
		});
	}

	_refreshRelationshipViewManager(selector = "") {
		this._dom.find(selector + ' .relationship-view-manager').each(function(){
			$(this).data("object").refresh();
		});
	}

	_populateDomAjax(){
		if(this._pageUrl){
			$.ajax({url: this._pageUrl + ".js", type: "GET" });
		}
	}

	_populateItemsAjax(selector = ""){
		this._dom.find(selector + " .ajax-item").each(function(){
			$.ajax({url: $(this).data("url"), type: "GET" });
		});
	}

	// CALLBACKS

	_redirectCallback(json){
		document.location.href = json.url;
	}

	_refreshCallback(json){
		this.refresh();
	}

	_refreshDataTablesCallback(json){
		this.closeModals();
		this._dom.find(".ajax-dataTable").data("object").refresh();
	}	

	_deleteCallback(json){
		this._dom.find("#" + json.id).data("object").delete();
		this.closeModals();
	}

	_createCallback(json){
		var modal = this.activeModal();
		if(modal){
			var dom_item = $(modal.data("object").createSelector).first();
			if(dom_item){
				dom_item.data("object").create(json);
			}		
		}
		this.closeModals();
	}

	_popModalCallback(json){
		var modal = this.activeModal();
		if(modal){
			modal.data("object").popModalStack(json);
		}
	}

}