function applicationModalShow(params){
	var applicationModal =  _ncaptsPage.getApplicationModal();
	applicationModal.set_params(params);
	applicationModal.show();
}

function applicationIframeModalShow(params){
	var applicationModal = _ncaptsPage.getApplicationIframeModal();
	applicationModal.set_params(params);
	applicationModal.show();
}

function createNewParams(populateUrl, submitUrl, callback, model, title, config={}){
	var params = {
		"populate-url": populateUrl,
		"submit-url": submitUrl,
		"callback": callback,
		"model": model,
		"title" : title,
		"submit-type" : "POST"
	};

	for (var key in config){
	  params[key] = config[key];
	}	

	return params
}

function createEditParams(populateUrl, submitUrl, callback, deleteCallback, model, title, config={}){
	var deletable = config["deletable"] == true
	var params = {
		"populate-url": populateUrl,
		"submit-url": submitUrl,
		"delete-url": submitUrl,
		"delete-callback": deleteCallback,
		"callback": callback,
		"model": model,
		"title" : title,
		"deletable" : deletable,
		"submit-type" : "PUT"
	};
	for (var key in config){
	  params[key] = config[key];
	}	
	return params
}

function createViewParams(populateUrl, title, config={}){
	var params = {
		"populate-url": populateUrl,
		"title" : title
	};

	for (var key in config){
	  params[key] = config[key];
	}	
	return params
}

window.showViewModal = function showViewModal(populateUrl, title, config={} ){
	params = createViewParams(populateUrl, title.replaceAll('-',' '), config);
	applicationModalShow(params);
}

window.showNewModal =function showNewModal(populateUrl, submitUrl, model, title, callback="[redirect]", config={}){
	params = createNewParams(populateUrl, submitUrl, callback, model, title.replaceAll('-',' '), config);
	applicationModalShow(params);
}

window.showEditModal =function showEditModal(populateUrl, submitUrl, model, title, callback="[refresh]", deleteCallback = "[refresh]", config={}){
	params = createEditParams(populateUrl, submitUrl, callback, deleteCallback, model, title.replaceAll('-',' '), config);
	applicationModalShow(params);
}

window.showIframeEditModal =function showIframeEditModal(populateUrl, submitUrl, model, title, callback="[refresh]", deleteCallback = "[refresh]", config={}){
	params = createEditParams(populateUrl, submitUrl, callback, deleteCallback, model, title.replaceAll('-',' '), config);
	applicationIframeModalShow(params);
}
