export default class AjaxCheckboxCollection{

	constructor(ajaxCheckboxCollectionDom){
		this._dom = ajaxCheckboxCollectionDom;
	  this._dom.data("object",this);
	  this._turnOnUrl = ajaxCheckboxCollectionDom.data("turn-on-url");
	  this._turnOffUrl = ajaxCheckboxCollectionDom.data("turn-off-url");
	  this._init();
	  this.refresh();
	}

	//public
	refresh(){
		this._setCounterHeaders();
	}


	//private

	_init(){
		var thisObj = this;
		this._dom.find("input[type=checkbox]").on("change",function() {
			var param = $(this).data("param");
		  var status = $(this).prop('checked');
			var url = "";
			if(status){
				url = thisObj._turnOnUrl + param;
			} else {
				url = thisObj._turnOffUrl + param;
			}
			window._ncaptsPage.ajaxJsonPutCall(url)
			thisObj.refresh();
		});
	}

	_setCounterHeaders(){
		this._dom.find(".checkbox-group").each(function() {
			var counter = 0;
			var totalCounter = 0;
			$(this).find("input[type=checkbox]").each(function() {
				if ($(this).prop('checked')){
					counter = counter + 1;
				}
				totalCounter = totalCounter + 1;
			});
			if(counter > 0){
				$(this).find(".check-count").html("&nbsp(" + counter + " of " + totalCounter + " Selected)");
			}else{
				$(this).find(".check-count").html("")
			}
		});
	}

}