// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled..

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("materialize-css")
require('datatables.net-dt')
require('datatables.net-buttons-dt')
require('datatables.net-buttons/js/buttons.print.js')
require('datatables.net-fixedcolumns-dt')
require('datatables.net-fixedheader-dt')
require('datatables.net-responsive-dt')
require('datatables.net-scroller-dt')
require('select2')
require("packs/ncapts-page")
require("packs/ncapts-modal")
require("packs/detail-view-manager")
require("packs/modal-init-functions")
require("packs/ajax-datatable")
import 'materialize-css/dist/js/materialize'

/* Datatables CSS */
import "datatables.net-dt/css/jquery.dataTables.min.css";

/* Select2 css */
import "select2/dist/css/select2.min.css";

/* Materialize css */
import "materialize-css/sass/materialize";

/* Best in Place */
import "./best_in_place"

/* Timepicker */
import "./timepicker"



/* add jQuery to the browser */
import JQuery from 'jquery';
window.$ = window.JQuery = window.jQuery = JQuery;


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// UTILITY FUNCTIONS

//resize iframe to content
window.resizeIframe = function resizeIframe(obj) {
  obj.style.height = (obj.contentWindow.document.body.scrollHeight * 1.1) + 'px';
}

// CURRENCY INPUTS

window.localStringToNumber = function localStringToNumber( s ){
  return Number(String(s).replace(/[^0-9.-]+/g,""))
}

window.currencyInputOnFocus = function currencyInputOnFocus(e){
  var value = e.target.value;
  e.target.value = value ? window.localStringToNumber(value) : ''
  e.target.select();
}

window.currencyInputOnBlur = function currencyInputOnBlur(e){
  var value = e.target.value

  var options = {
      maximumFractionDigits : 2,
      currency              : 'USD',
      style                 : "currency",
      currencyDisplay       : "symbol"
  }
  
  e.target.value = value 
    ? window.localStringToNumber(value).toLocaleString(undefined, options)
    : ''
}
