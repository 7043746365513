export default class AjaxDataTable{

	constructor(dataTableDom){
		this._rememberFocus = null;
		this._filters = {};
		this._dataTable = null;
		this._dom = dataTableDom;
	  this._dom.data("object",this);
	  this._moveUpUrl = this._dom.data("move-up-url");
	  this._moveDownUrl = this._dom.data("move-down-url");
	  this._removeUrl = this._dom.data("remove-url");
	  this._editFunction = this._dom.data("edit-function");
		// this._filterModalDom = this._dom.find(".filter-modal").first();

		// this._updateFiltersFromDom();
		this._column_terms = JSON.parse(this._dom.data("columns").replace(/'/g , '"')); //json parser breaks on apostrophe
		this._initDom(this._column_terms);
	}

	//public
	refresh(){
		this._updateDataTableDomObjects();
		window._ncaptsPage.init("#" + this._dom.attr("id"));
	}

	// removeFilter(filterName){
	// 	this._filters[filterName] = {value: "", display: ""};
	// 	this._resetFilterModal();
	// 	this._updateFilterModal();
	// 	this._updateDataTableDomObjects();
	// 	this.refresh();
	// }

	//private

	// _updateFiltersFromDom(){
	// 	var filters = {};
	// 	this._dom.find(".dataTable-filter").each(function(){
	// 		var filter = $(this);
	// 		filters[filter.data("key")] = {value:filter.data("value"), display:filter.data("display") }
	// 	});
	// 	this._filters = filters;
	// }

	// _updateFiltersFromModal(){
	// 	var filters = {};
	// 	this._filterModalDom.find('.input').each(function(){
	// 		var key = $(this).attr("name");
	// 		var input = $(this);
	// 		if(input.attr('type') == "checkbox"){
	// 			value = input.is(':checked');
	// 		}else{
	// 			var value = input.val();
	// 		}
	// 		if(input.find('option:selected').length > 0){
	// 			var display = input.find('option:selected').text();
	// 		}else if(input.attr('type') == "checkbox"){
	// 			var display = value ? "True" : "False";
	// 		}else{
	// 			var display = input.val();
	// 		}
	// 		filters[key] = {value:value, display:display};
	// 	});
	// 	this._filters = filters;
	// }

	// _resetFilterModal(){
	// 	this._filterModalDom.find('input').val("");
	// 	this._filterModalDom.find('select').val("");
	//   this._filterModalDom.find('.select2').val("").trigger("change");
	// }

	// _updateFilterModal(){
	// 	for(var filterName in this._filters){
	// 		var filterValue = this._filters[filterName]["value"];
	// 		var input = this._filterModalDom.find(".modal-input-group [name='" + filterName + "']").first();
	// 		if(input.attr('type') == "checkbox"){
	// 			input.prop("checked", filterValue);
	// 		}else{
	// 			input.val(filterValue).trigger('change');
	// 		}
	// 	}
	// }

	// _updateFilterBarDom(){
	// 	var filterBar = this._dom.find(".filter-bar");
	// 	var labelsToAdd = [];
	// 	var dataTableObjectStr = '$(".reef-dataTable").first().data("reef-object")'
	// 	for(var key in this._filters){
	// 		if(this._filters[key]["value"] != ""){
	// 			var tempKey = '"' + key + '"';
	// 			var label = $("<div class='label'><a onclick = 'var dataTable =" + dataTableObjectStr + "; dataTable.removeFilter(" + tempKey + ");'>"
	// 			  + $("#close-icon").html() +" </a><span>"
	// 				+toTitleCase(key.replace(/_/gi, ' '))+" : "+this._filters[key]['display']+"</span></div>");
	//   		labelsToAdd.push(label);
	//   	}
	// 	}

	// 	filterBar.show();
	// 	if(labelsToAdd.length == 0){
	// 		filterBar.hide();
	// 	}

	// 	this._dom.find(".filter-bar .label").remove();

	// 	for(var i = 0; i < labelsToAdd.length; i++){
	// 		filterBar.append(labelsToAdd[i]);
	// 	}
	// }

	_updateDataTableDomObjects(){
		// this._updateFilterBarDom();
		this._updateTableTitleDom();
		this._updateDataTableAjax();
		this._addRowButtons();
	}

	_updateTableTitleDom(){
		var bookmark = this._dom.find(".dataTable-bookmark");
		var title = this._dom.find(".dataTable-title");
		title.html(title.data("default"));
		bookmark.html("");	
	}

	_updateDataTableAjax(){
		var this_datatable = this;
		this._dataTable.ajax.reload(function(){
		}, false);
	}

	_addRowButtons(){
		this._addOptionButtons();
		this._addOrderButtons();
		this._addInfoLinks();
		this._addCelClasses();
		this._initBestInPlace();
		this._initCheckBoxes();
		this._initTooltips();
	}

	_addCelClasses(){
		this._dom.find('div.total-cel').not(".initialized").each(function(){
			var $this = $(this);
			$(this).closest('td').addClass('total-cel');
			$this.addClass("initialized");
		});
		this._dom.find('div.enforcement-item-cel').not(".initialized").each(function(){
			var $this = $(this);
			var one_click = false;
			$(this).find("i").hide();
			$(this).closest("td").hover( function(){
				if($(this).find("input").length == 0){
					$(this).find("i").show();
				}
			}, function(){
				$(this).find("i").hide();
			}).addClass('inline-edit-cel').click(function(event){
				if(one_click == false){
					one_click = true;
					$(this).find("i").hide();
					$(this).find("span").click();
					$(this).addClass("selected");
					one_click = false;
				}
			});
			$this.addClass("initialized");
		});
	}

	_initBestInPlace(){
		var this_datatable = this;
		this._dom.find('.best_in_place').not(".initialized").each(function(){
			var bip = $(this);
			bip.best_in_place();

			bip.bind("ajax:success", function (event, data, status, xhr) {
				this_datatable._rememberFocus = parseInt(bip.attr('tabindex')) + 1;
				this_datatable.refresh(bip);
				window._ncaptsPage.checkJsonForNotification($.parseJSON(data));
			});

			bip.bind("ajax:error", function (event, data, status, xhr) {
				window._ncaptsPage.checkJsonForNotification($.parseJSON(data.responseText));
			});

			bip.keydown(function(e) {
				var code = e.keyCode || e.which;
				console.log(e.shiftKey);
				var $this = $(this)		    	
				if (code === 9 || code === 13) { 
					// var dataBipValue = $this.attr('data-bip-original-content');
					// var dataBipOriginalValue = $this.attr('data-bip-value');
					// var tabindex = parseInt($this.attr('tabindex'));
					// if(dataBipValue != dataBipOriginalValue){
					// 	$(".best_in_place[tabindex=" + tabindex + "]").click();
					// }
					if(code === 9){
						if(e.shiftKey){
							this_datatable._rememberFocus = parseInt(bip.attr('tabindex')) - 1;
						}else{
							this_datatable._rememberFocus = parseInt(bip.attr('tabindex')) + 1;
						}
						this_datatable.refresh(bip);
					}else{
						this_datatable._rememberFocus = parseInt(bip.attr('tabindex')) + 1;
						this_datatable.refresh(bip);
					}

				}

			});

			bip.on("click",function(e){
				if(this_datatable._rememberFocus){
					this.scrollIntoView({block: 'nearest', inline: 'center'});
				}
			})

			bip.addClass("initialized");
		});
		if(this_datatable._rememberFocus){
			$(".best_in_place[tabindex=" + this_datatable._rememberFocus + "]").click();
		}
	}

	_initCheckBoxes(){
		var this_datatable = this;
		this._dom.find('div.delete-checkbox-div').not(".initialized").each(function(){
			var check_box = $(this);
			var id = $(this).data("id");
			check_box.append($("<label><input class='dataTable-checkbox' data-id='" + id + "' type='checkbox' /><span></span></label>"));
			check_box.css("text-align", "center");

			check_box.addClass("initialized");
		});
		this._dom.find('div.delete-checkbox-master-div').not(".initialized").each(function(){
			var check_box_master = $(this);
			check_box_master.css("text-align", "center");
			var to_add = $("<label><input class='dataTable-checkbox-master' type='checkbox' /><span></span></label>");
			check_box_master.append(to_add);

			to_add.on("change",function(e){
				this_datatable._dom.find(".dataTable-checkbox").prop("checked", to_add.find("input").is(':checked') );
			})

			check_box_master.addClass("initialized");
		});
	}

	_initTooltips(){
		this._dom.find('.tooltipped').not(".initialized").each(function(){
			$('.tooltipped').addClass('initialized').tooltip();
		})
	}

	_addOptionButtons(){
		var this_datatable = this;
		var optionsHTML = ""

		if(this._removeUrl && this._removeUrl != ""){
			optionsHTML = optionsHTML + $('#remove-icon-btn').html();
		}
		if(this._editFunction && this._editFunction != ""){
			optionsHTML = optionsHTML + $('#edit-icon-btn').html();			
		}

		this._dom.find(".dataTable-row-options").html(optionsHTML);

		this._dom.find("tbody tr").each(function(){
			var row = $(this);
			var rowId = row.attr('id');
			if(this_datatable._removeUrl && this_datatable._removeUrl != ""){
				var removeBtn = row.find(".remove-btn").addClass("ncapts-red").click(
					function(){
						window._ncaptsPage.confirmModal(
							function(){
								this_datatable._jsonCallRefresh(this_datatable._removeUrl.replace("remove_btn_id", rowId));
							}
						)
					}
				);
			}
			if(this_datatable._editFunction && this_datatable._editFunction != ""){
				var editBtn = row.find(".edit-btn").addClass("ncapts-secondary");
				var editFunction = this_datatable._editFunction.replaceAll("edit_btn_id", rowId);
				editBtn.attr("onclick",editFunction);
			}
		});
	}

	_addInfoLinks(){
		this._dom.find("tbody tr").each(function(){
			var row = $(this);
			row.find(".info-link").each(function(){
				var infoLink = $(this);
				infoLink.click(function(){
					window._ncaptsPage.ajaxJsGetCall(infoLink.data("url"));
				})
			});
		});
	}

	_addOrderButtons(){
		if(!this._moveUpUrl || !this._moveDownUrl){
			return;
		}
		
		var this_datatable = this;
		var rowCount = this._dom.find("tbody tr").length;
		var buttonsHTML = "";

		var counter = 0;
		this._dom.find("tbody tr").each(function(){
			//if(counter < (rowCount - 1)){
			if(true){
				buttonsHTML = buttonsHTML + $('#down-icon-btn').html();
			}
			//if(counter > 0 ){
			if(true){
				buttonsHTML = buttonsHTML + $('#up-icon-btn').html();
			}
			$(this).find(".dataTable-order-btns").html(buttonsHTML);
			counter = counter + 1;
			buttonsHTML = "";
		});

		this._dom.find("tbody tr").each(function(){
			var row = $(this);
			var rowId = row.attr('id');
			if(this_datatable._moveUpUrl && this_datatable._moveUpUrl != ""){
				var upBtn = row.find(".up-btn").addClass("ncapts-primary").click(function(){
					this_datatable._jsonCallRefresh(this_datatable._moveUpUrl.replace("move_btn_id", rowId));
				});
			}
			if(this_datatable._moveDownUrl && this_datatable._moveDownUrl != ""){
				var upBtn = row.find(".down-btn").addClass("ncapts-primary").click(function(){
					this_datatable._jsonCallRefresh(this_datatable._moveDownUrl.replace("move_btn_id", rowId));
				});
			}
		});

	}

	_createUrl(type){
		var url = this._dom.data("url")		
		if(type == "pdf-url"){
			url = this._dom.data("pdf-url");
		}else if (type == "spreadsheet-url"){
			url = this._dom.data("spreadsheet-url");
		}
		var url_variables = "";
		// for (var key in this._filters){
		// 	if(this._filters[key]["value"] != ""){
		// 		var symbol = url.indexOf("?") == -1 ? "?" : "&";
		// 		url = url + symbol + key + "=" + this._filters[key]["value"];
		// 	}
		// }
		return url;
	}

	_export(type){
		if(type == "pdf"){
			var url = this._createUrl("pdf-url");
		}else if(type == "spreadsheet"){
			var url = this._createUrl("spreadsheet-url");
		}
		var order = this._dataTable.order();
		var sortArray = []
		for(var i = 0; i < order.length; i++){
			sortArray.push([this._column_terms[order[i][0]].data, order[i][1]]);
		}
		var encodedArray = jQuery.param({sort: sortArray});
		url = url.includes('?') ? url + "&" + encodedArray : url + "?sort=" + encodedArray;
		window.open(url);
	}

	_initDom(columns){
		var this_datatable = this;

		this._dom.find(".dataTable-select-wrapper a").click(function(){this_datatable._addClicked();});

		//set dom objects
		// this._updateFilterBarDom();
		this._updateTableTitleDom();
		this._addRowButtons();

		//init dataTable
		var dataTable = this._dom.find(".dataTable").first();
		var pageScrollPos = null;
		var options = {
			"fnDrawCallback": function(oSettings, json) {
  			this_datatable._addRowButtons();
        $('div.dataTables_scrollBody').scrollTop(pageScrollPos);
    	},
	    "preDrawCallback": function (settings) {
	        pageScrollPos = $('div.dataTables_scrollBody').scrollTop();
	    },   	
			"dom": '<"top"<"title-block">fl>rt<"bottom"ip><"clear">',
		  "processing": true,
		  "serverSide": true,
		  "searchDelay": 1000,
		  "ajax": this._createUrl(),
		  "pagingType": "full_numbers",
			"order": [[ dataTable.data("order-col") ? dataTable.data("order-col") : 0,
				dataTable.data("order-dir") ? dataTable.data("order-dir") : "desc"]],
		  "columns": columns,
		  "stateSave": true,
		  language: {
		    search: "_INPUT_",
		    searchPlaceholder: "Search...",
				lengthMenu: "_MENU_"
		  }
		}

		if (this_datatable._dom.data("page-length")){
			options["pageLength"] = parseInt(this_datatable._dom.data("page-length"));
		}		
		if (this_datatable._dom.data("disable-sort") == true){
			options["ordering"] = false;
		}
		if (this_datatable._dom.data("scroll-x") == true){
			options["scrollX"] = true;
		}
		if (this_datatable._dom.data("scroll-y")){
			options["scrollY"] = this_datatable._dom.data("scroll-y");
      options["scrollCollapse"] = true
      options["paging"] = false
		}
		if (this_datatable._dom.data("fixed-columns") == true){
			options["fixedColumns"] = true;
		}
		if (this_datatable._dom.data("fixed-columns") == 2){
			options["fixedColumns"] = {
        leftColumns: 2
    	};
		}
		if (this_datatable._dom.data("fixed-header")){
			options["fixedHeader"] = true;
		}
		this._dataTable = dataTable.DataTable(options);
		this._dom.find(".title-block").html(this._dom.find(".temp-title-block").html());
		//this._dom.find(".temp-title-block").contents().appendTo(this._dom.find(".title-block"));


		// this._dom.find('.filter-modal-submit-btn').click(function(){
		// 	this_datatable._filterModalDom.modal('hide');
		// 	this_datatable._updateFiltersFromModal();
		// 	this_datatable._updateDataTableDomObjects();
		// });

		// this._dom.find('.filter-modal-clear-btn').click(function(){
		// 	this_datatable._resetFilterModal();
		// });

		// this._dom.find('.open-filter-modal-btn').click(function(){
		// 	this_datatable._filterModalDom.modal("show");
		// 	this_datatable._resetFilterModal();
		// 	this_datatable._updateFilterModal();
		// });

		this._dom.find('.export-pdf-btn').click(function(){
			this_datatable._export('pdf');
		});

		this._dom.find('.export-spreadsheet-btn').click(function(){
			this_datatable._export('spreadsheet');
		});

		this._dom.on("mousemove", function(e){
			if(this_datatable._rememberFocus){
				this_datatable._rememberFocus = null;
			}
		})

	}

	_addClicked() {
		var this_datatable = this;
		var select = this._dom.find(".dataTable-select-wrapper").find("select");
		if(select){
			var url = this._dom.find(".dataTable-select-wrapper").find("select").val();
			if(url && url != ""){
				var method = select.data("method");
				if(method == "POST"){
					window._ncaptsPage.ajaxJsonPostCall(url,function(){this_datatable.refresh();});
				}else{
					window._ncaptsPage.ajaxJsonPutCall(url,function(){this_datatable.refresh();});
				}
			}
		}
	}

	_jsonCallRefresh(url){
		var this_datatable = this;
		if(url && url != ""){
			if(url.indexOf("method=delete") != -1){
				window._ncaptsPage.ajaxJsonDeleteCall(url,function(){this_datatable.refresh();});
			}else{
				window._ncaptsPage.ajaxJsonPutCall(url,function(){this_datatable.refresh();});
			}
		}
	}

}