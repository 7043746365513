export default class RelationshipViewManager{

	constructor(relationshipViewManagerDom){
		this._dom = relationshipViewManagerDom;
	  this._dom.data("object",this);
	  this._initSelect();
	  this.refresh();
	}

	//public

	// selectByIndex(index){
	// 	var obj = this._dom.find( ".view-btn:eq(" + index + ")");
	// 	this._select(obj);
	// }

	refresh(){
		var obj = this._dom.find('.view-select').find(':selected');
		this._select(obj);
	}

	//private
	_select(buttonDom){
	  this._clear();
	  var url = this._dom.find('.view-select').find(':selected').data("url");
		this._populateViewAjax(url);
	}

	_clear(){
		this._dom.find(".relationship-view-wrapper").html("");
	}

	_initSelect(){
		var thisObj = this;
		this._dom.find('.view-select').on('select2:select', function (e) {
			var url = e.params.data.element.dataset["url"];
			thisObj._populateViewAjax(url);
		});
	}

	_populateViewAjax(url){
		_ncaptsPage.ajaxJsGetCall(url + ".js");
	}
}